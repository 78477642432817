var menueAktiv = false;
var hamburger = document.getElementById('menue__hamburger')
var menueSvgs = document.querySelector('.menue__svgs');


var y = 0;
function menueToggle() {
    
    if (menueAktiv) {
        menueAktiv = false;
        document.body.setAttribute('data-menue-aktiv', menueAktiv);
        hamburger.style.marginRight = '';
        document.body.style.paddingRight = '';
        document.body.style.top = '';
        window.scrollTo(0, y);
    } else {
        menueAktiv = true;
        scrollbar = window.innerWidth - document.documentElement.clientWidth;
        y = window.scrollY;
        document.body.setAttribute('data-menue-aktiv', menueAktiv);
        var marginOriginal = parseFloat(window.getComputedStyle(hamburger).marginRight);
        hamburger.style.marginRight = marginOriginal + scrollbar + 'px';
        document.body.style.paddingRight = scrollbar + 'px';
        document.body.style.top = -y + 'px';
        menueSvgs.style.top = y - 140 + 17 + 'px';
    }
}


// Hamburgermenü aktivieren
hamburger.addEventListener('click', event => {
    menueToggle();
});

class WeichesScrollenZu {
    constructor(linkKlasse) {
        this.links = document.querySelectorAll(linkKlasse);
        this.scrollToSection = this.ScrollFunktion.bind(this);
        this.links.forEach(button => {
            button.addEventListener('click', this.scrollToSection);
        });
    }

    ScrollFunktion(event) {
        event.preventDefault();

        const targetSectionId = event.currentTarget.getAttribute('href'); // Auf event.target zugreifen
        const targetSection = document.querySelector(targetSectionId);
        const targetSectionTop = targetSection.getBoundingClientRect().top + window.scrollY;
        const targetPosition = targetSectionTop;

        window.scrollTo({
            top: targetPosition,
            left: 0,
            behavior: 'smooth'
        });
    }
}

document.addEventListener('DOMContentLoaded', function () {
    const body = document.body;
    if (body.getAttribute('data-site') === 'wiederInsLeben') {
        const menueScrollen = new WeichesScrollenZu('.menue__link');
    }
});